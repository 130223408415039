import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { ApiDevicesService } from '../service/api-devices.service';
import { Device } from '../model/device';
import { distinct, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DevicesSubjectServiceService {
  private devices$ = new ReplaySubject<Device[]>(1);

  constructor(
    private apiDevicesService: ApiDevicesService,
  ) {
    this.apiDevicesService.getDevices()
      .subscribe(devices => {
        this.devices$.next(devices.sort(this.sortByConnectedTime));
      });
  }

  public getObservable(deviceId: number): Observable<Device> {
    return this.devices$.asObservable()
      .pipe(
        map(devices => devices.find(d => d.id === deviceId)),
        distinct()
      );
  }

  public getObservableOfAllDevices(): Observable<Device[]> {
    return this.devices$.asObservable();
  }

  public getSubjectsObservable() {
    return this.devices$.asObservable();
  }

  public next(devices: Device[]) {
    this.devices$.next(devices.sort(this.sortByConnectedTime));
  }

  private sortByConnectedTime(a: Device, b: Device): number {
    return a.connectedAt.getTime() - b.connectedAt.getTime();
  }
}
