import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinct, map } from 'rxjs/operators';
import { DeviceEvent } from '../model/device-event';

@Injectable({
  providedIn: 'root'
})
export class DeviceEventsSubjectService {
  private events$ = new BehaviorSubject<DeviceEvent[]>(undefined);

  constructor() {
  }

  public getObservable(): Observable<DeviceEvent[]> {
    return this.events$.asObservable();
  }

  public getEventsObservableForDevice(deviceId: number): Observable<DeviceEvent[]> {
    return this.events$.asObservable()
      .pipe(
        map(events => events.filter(e => e.deviceId === deviceId)),
        distinct()
      );
  }

  public next(devicesEvent: DeviceEvent[]) {
    this.events$.next(devicesEvent);
  }
}
