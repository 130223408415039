import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { ModalConfirmDeleteComponent } from './shared/components/modal-confirm-delete/modal-confirm-delete.component';
import { environment } from '../environments/environment';
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireModule } from "@angular/fire/compat";
import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';
import { myRxStompConfig } from './my-rx-stomp.config';
import { errorHandlerFactory } from './app-error-handler.service';
import { AuthenticationService } from './shared/services/authentication.service';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { WebSocketListenersService } from './@core/service/web-socket-listeners.service';
import { ModalConfirmRepairComponent } from './shared/components/modal-confirm-repair/modal-confirm-repair.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LanguageTranslationModule,
    AppRoutingModule,
    NgbModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true,
      countDuplicates: true,
      resetTimeoutOnDuplicate: true,
      progressBar: true,
      positionClass: 'toast-top-right',
    }),
    LoadingBarModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    AngularFireMessagingModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    FontAwesomeModule,
  ],
  declarations: [
    AppComponent,
    ModalConfirmDeleteComponent,
    ModalConfirmRepairComponent,
  ],
  providers: [
    AuthGuard,
    AuthenticationService,
    WebSocketListenersService,
    {
      provide: InjectableRxStompConfig,
      useValue: myRxStompConfig
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
      deps: [InjectableRxStompConfig]
    },
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
