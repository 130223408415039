import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable } from 'rxjs';
import { flatMap, map, take } from 'rxjs/operators';
import { StringKeyedObject } from '../types/general';
import firebase from "firebase/compat/app";
import * as auth from 'firebase/auth';
import UserCredential = firebase.auth.UserCredential;
import User = firebase.User;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  readonly authState$: Observable<User | null>;

  constructor(
    private fireAuth: AngularFireAuth,
  ) {
    this.authState$ = this.fireAuth.authState;
  }

  getAuthenticationHeaders(): Observable<StringKeyedObject<string>> {
    return this.getCurrentUserToken()
      .pipe(
        take(1),
        map(token => {
          const authValue = `Bearer ${token}`;
          return token
            ? {
              Authorization: authValue
            }
            : {};
        })
      );
  }

  signUp(email: string, password: string) {
    return this.fireAuth
      .createUserWithEmailAndPassword(email, password)
      .then((user: UserCredential) => {
        console.log('Successfully signed up!', user);
        // localStorage.setItem(this.LOCALSTORAGE_EMAIL_KEY, email);
        const currentUrl = document.location.href;
        const loginUrl = currentUrl.substring(0, currentUrl.lastIndexOf('/')) + '/login';
        return user.user.sendEmailVerification({
          url: loginUrl
        });
      }).catch(error => {
        console.log('Something is wrong:', error.message);
      });
  }

  activateAccount(code: string) {
    return this.fireAuth.applyActionCode(code);
  }

  resetPassword(email: string) {
    const currentUrl = document.location.href;
    const resetPasswordUrl = currentUrl.substring(0, currentUrl.lastIndexOf('/')) + '/login';
    return this.fireAuth.sendPasswordResetEmail(email, {
      url: resetPasswordUrl,
      handleCodeInApp: true
    });
  }

  changePassword(code, newPassword) {
    return this.fireAuth.confirmPasswordReset(code, newPassword);
  }

  signIn(email: string, password: string) {
    return this.fireAuth
      .signInWithEmailAndPassword(email, password);
  }

  signInGoogle() {
    return this.fireAuth.signInWithPopup(new auth.GoogleAuthProvider());
  }

  signInFacebook() {
    return this.fireAuth.signInWithPopup(new auth.FacebookAuthProvider());
  }

  signOut() {
    return this.fireAuth
      .signOut();
  }

  getCurrentUserToken(): Observable<string | undefined> {
    return this.authState$.pipe(
      flatMap(user => user?.getIdToken())
    );
  }
}
