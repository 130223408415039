<div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{'delete' | translate | titlecase}} {{'device' | translate}}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>{{'Are you sure you want to delete' | translate}} <span class="text-primary">{{objectToDelete}}</span>?</p>
    <p>
        <span class="text-danger">{{'This operation can not be undone' | translate}}.</span>
    </p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close(false)">{{'Cancel' | translate}}</button>
    <button type="button" ngbAutofocus class="btn btn-danger" (click)="modal.close(true)">{{'Ok' | translate}}</button>
</div>
