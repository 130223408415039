import { Injectable } from '@angular/core';
import { User } from '../model/user';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApiUserService {

  constructor(
    private http: HttpClient,
  ) {
  }

  public getUserInfo(): Observable<User> {
    return this.http.get<User>(`${environment.apiUrl}/user/info`);
  }

  public updateUserInfo(user: User): Observable<any> {
    return this.http.put<User>(`${environment.apiUrl}/user`, user);
  }

  public sendTokenToApi(token: string): Observable<string> {
    if (token?.length > 0) {
      return this.http.post(`${environment.apiUrl}/user/token`, token, {responseType: 'text'});
    } else {
      throw new Error(`Token is empty: ${token}`);
    }
  }
}
