import { Entry } from './entry';
import { Adapter } from './i-adapter';
import {Injectable} from '@angular/core';
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

export class Device {
  id: number;
  name: string;
  lastSignal: number;
  connectedAt: Date;
  fwVersion: string;
  fwTargetVersion: string;
  lastStatus: LastStatus;
  lastStatusIcon?: string;
  lastSignalIcon?: string;
  lastHoursFilter?: number;
  entries?: Entry[];
  lastEntry?: Entry;
  chartOptions?: any;
  voltageSOC?: number;
  voltageSOCStyle?: string;
  useChartTemperature = false;
  useChartHumidity = false;
}

export enum LastStatus {
  'VALID' = 'VALID',
  'BELOW_THRESHOLD' = 'BELOW_THRESHOLD',
  'TIMEOUT' = 'TIMEOUT',
  'UNKNOWN' = 'UNKNOWN'
}

@Injectable({
  providedIn: 'root'
})
export class DeviceAdapter implements Adapter<Device> {
  adapt(item: any): Device {
    const device = new Device();
    device.id = item.id;
    device.name = item.name;
    device.lastSignal = item.lastSignal;
    device.connectedAt = new Date(item.connectedAt);
    device.lastStatus = item.lastStatus;
    device.fwVersion = item.fwVersion;
    device.fwTargetVersion = item.fwTargetVersion;
    return device;
  }
}
