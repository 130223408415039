import { ErrorHandler, isDevMode } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../environments/environment';
// import { TranslateService } from '@ngx-translate/core';
// import { ToastrService } from 'ngx-toastr';
// import pkg from '../../package.json';
const version = require('../../package.json').version;

export function errorHandlerFactory(/*toastrService: ToastrService, translateService: TranslateService*/) {
  console.log(`App version: ${version}`);
  if (isDevMode()) {
    return new ErrorHandler();
  } else {
    console.log('Initializing Sentry error handler');
    return new SentryErrorHandler(/*toastrService, translateService*/);
  }
}

export class SentryErrorHandler implements ErrorHandler {
  constructor(
    // public toastService: ToastrService,
    // private translateService: TranslateService,
  ) {
    Sentry.init({
      dsn: 'https://8376147b7fc848ffb5a8b8653aecb6ff@sentry.io/1864808',
      release: version,
      environment: environment.apiUrl.indexOf('dev') >= 0
        ? 'dev'
        : environment.production
          ? 'prod'
          : undefined
    });
  }

  handleError(error: any) {
    Sentry.captureException(error.originalError || error);
    // this.toastService.error(this.translateService.instant('An error occurs, please try again later'));
    throw error;
  }
}
