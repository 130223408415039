import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Device, DeviceAdapter } from '../model/device';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DeviceEvent } from '../model/device-event';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiDeviceEventsService {
  constructor(
    private http: HttpClient,
  ) { }

  public getEventsForDevice(deviceId: number): Observable<DeviceEvent[]> {
    return this.http.get<DeviceEvent[]>(`${environment.apiUrl}/event/device/${deviceId}`)
      .pipe(take(1));
  }

  public getAllEvents(): Observable<DeviceEvent[]> {
    return this.http.get<DeviceEvent[]>(`${environment.apiUrl}/event`)
      .pipe(take(1));
  }
}
