import { Injectable } from '@angular/core';
import { RxStompService } from '@stomp/ng2-stompjs';
import { distinct, filter, flatMap, map, tap } from 'rxjs/operators';
import { ApiDevicesService } from './api-devices.service';
import { ApiDeviceEventsService } from './api-device-events.service';
import { DevicesSubjectServiceService } from '../subject/devices-subject-service.service';
import { DeviceEventsSubjectService } from '../subject/device-events-subject.service';
import { Device } from '../model/device';
import { Observable, Subscription } from 'rxjs';
import { Entry, EntryAdapter } from '../model/entry';

@Injectable({
  providedIn: 'root'
})
export class WebSocketListenersService {
  private watchers: {[id: number]: Subscription} = {};

  constructor(
    private rxStompService: RxStompService,
    private apiDevicesService: ApiDevicesService,
    private apiEventsService: ApiDeviceEventsService,
    private devicesSubjectServiceService: DevicesSubjectServiceService,
    private deviceEventsSubjectService: DeviceEventsSubjectService,
  ) {}

  public init() {
  }

  private cleanInvalidSubscriptions(devices: Device[]) {
    const devicesMap = ApiDevicesService.createDevicesMap(devices);
    Object.keys(this.watchers)
      .forEach(id => {
        if (!devicesMap[id]) {
          this.watchers[id].unsubscribe();
        }
      });
  }

  private registerDeviceEntryListener(deviceId: number): Subscription {
    return this.rxStompService
      .watch(`/topic/event.device.${deviceId}`)
      .pipe(
        flatMap(() => this.apiEventsService.getAllEvents())
      )
      .subscribe(events => {
        this.deviceEventsSubjectService.next(events);
      });
  }
}
