import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-confirm-repair',
  templateUrl: './modal-confirm-repair.component.html',
  styleUrls: ['./modal-confirm-repair.component.scss']
})
export class ModalConfirmRepairComponent implements OnInit {

  @Input()
  deviceName: string;

  @Input()
  title: string;

  constructor(
    public modal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
  }
}
