import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';
import { flatMap, map, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    public injector: Injector
  ) {
  }

  /**
   * R3 Injector error workaround
   */
  get authService(): AuthenticationService {
    return this.injector.get(AuthenticationService);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.shouldAddHeaders(request)
      ? this.authService.getAuthenticationHeaders()
        .pipe(
          take(1),
          map(headers => request.clone({
            setHeaders: headers
          })),
          flatMap(req => next.handle(req))
        )
      : next.handle(request);
  }

  /**
   * Authenticated requests are sent only to
   * our API, nowhere else.
   */
  shouldAddHeaders(request: HttpRequest<any>): boolean {
    return request.url.startsWith(environment.apiUrl);
  }
}
