<div class="row" *ngIf="items.length > 1">
    <div class="col-xl-12">
        <ol class="breadcrumb py-0 px-2 m-0 mb-3">
            <li class="breadcrumb-item" *ngFor="let item of items" [class.active]="items.indexOf(item) === items.length - 1">
                <i class="fa mr-1" [ngClass]="item.icon"></i>
                <a *ngIf="item.path && items.indexOf(item) !== items.length - 1" [routerLink]="[item.path]">{{item.name | translate}}</a>
                <a *ngIf="!(item.path && items.indexOf(item) !== items.length - 1)">{{item.name | translate}}</a>
            </li>
        </ol>
    </div>
</div>
