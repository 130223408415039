import { Injectable } from '@angular/core';
import { Subscription, throwError } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { ApiUserService } from './api-user.service';
import { catchError, flatMap, mergeMapTo, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NotificationStatus } from '../model/notification-status.enum';
import 'firebase/messaging';
import { HttpErrorResponse } from '@angular/common/http';

// import { messaging } from 'firebase';

@Injectable({
    providedIn: 'root'
})
export class FirebaseMessagingService {
    public static KEY_SENT_FCM_TOKEN = 'lastSentFcmToken';
    public static KEY_SENT_FCM_TIMESTAMP = 'lastSentFcmTokenTimestamp';
    private messageSubscription: Subscription;

    constructor(
        private apiUserService: ApiUserService,
        private angularFireMessaging: AngularFireMessaging,
        private toastrService: ToastrService,
    ) {
        // if (this.isSupported()) {
        //     this.angularFireMessaging.messages.subscribe((_messaging: AngularFireMessaging) => {
        //       _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        //       _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        //     });
        // }
    }

  /**
   * request permission for notification from firebase cloud messaging
   *
   */
  requestPermission(): void {
    this.unregisterServiceWorkers();
    this.registerWithNewToken();
    if (!this.shouldSendToken()) {
      console.log('Current token is valid and recently sent so there is no need to send to API');
      return;
    }
    let messageToken;
    this.angularFireMessaging.requestPermission.pipe(
      mergeMapTo(this.angularFireMessaging.tokenChanges),
      tap(token => messageToken = token),
      flatMap(token => this.apiUserService.sendTokenToApi(token)),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 500 || messageToken) {
          console.log('requesting new token because current is rejected from API: ', messageToken);
          return this.angularFireMessaging.requestToken.pipe(
            tap(newToken => console.log('renewed token: ', newToken)),
            flatMap(newToken => this.apiUserService.sendTokenToApi(newToken))
          );
        } else {
          console.warn(error);
          return throwError(error);
        }
      })
    ).subscribe(
      response => {
        localStorage.setItem(FirebaseMessagingService.KEY_SENT_FCM_TOKEN, response);
        localStorage.setItem(FirebaseMessagingService.KEY_SENT_FCM_TIMESTAMP, String(new Date().getTime()));
        console.log('FCM Permission granted! sent to server:', response);
      },
      error => {
        console.error(error);
      });
  }

  private async unregisterServiceWorkers() {
    // firebase.default.app().delete()
    //   .then(() => console.log('deleted'))
    //   .catch(() => console.log('not deleted'));
    // .then(() => firebase.initializeApp(environment.firebase));
    localStorage.removeItem(FirebaseMessagingService.KEY_SENT_FCM_TOKEN);
    localStorage.removeItem(FirebaseMessagingService.KEY_SENT_FCM_TIMESTAMP);
    await navigator.serviceWorker.getRegistrations().then(function (registrations) {
      registrations.forEach(function (registration) {
        registration.unregister();
      });
    });
  }

  private async registerWithNewToken() {
    const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
    await navigator.serviceWorker.ready;
  }

  private shouldSendToken() {
    const lastSentTimestamp = Number.parseInt(localStorage.getItem(FirebaseMessagingService.KEY_SENT_FCM_TIMESTAMP), 10);
    const dayInSeconds = 3600 * 24;
    return localStorage.getItem(FirebaseMessagingService.KEY_SENT_FCM_TOKEN)?.length !== 152
      || isNaN(lastSentTimestamp)
      || lastSentTimestamp + dayInSeconds < new Date().getTime();
  }

//     requestPermission() {
//         this.angularFireMessaging.tokenChanges.pipe(
//             flatMap(token => this.apiUserService.sendTokenToApi(token)),
//         ).subscribe(
//             response => console.log('sent token to API'),
//             error => console.error('error when sending token to API', error)
//         );
//     }

    /**
     * hook method when new notification received in foreground
     */
    receiveMessage() {
        this.unregisterReceiver();
        this.messageSubscription = this.angularFireMessaging.messages.subscribe(
            (payload: any) => {
                console.log('new message received. ', payload);
                const notification: Notification = payload.notification;
                const status: NotificationStatus = NotificationStatus[notification.data?.status as string];
                let toastrStatus: string;
                switch (status) {
                    case NotificationStatus.INFO:
                        toastrStatus = this.toastrService.toastrConfig.iconClasses.info;
                        break;
                    case NotificationStatus.SUCCESS:
                        toastrStatus = this.toastrService.toastrConfig.iconClasses.success;
                        break;
                    case NotificationStatus.ERROR:
                        toastrStatus = this.toastrService.toastrConfig.iconClasses.error;
                        break;
                    case NotificationStatus.WARN:
                        toastrStatus = this.toastrService.toastrConfig.iconClasses.warning;
                        break;
                    default:
                        toastrStatus = this.toastrService.toastrConfig.iconClasses.info;
                        break;
                }
                this.toastrService.show(notification.body, notification.title, null, toastrStatus);
            });
    }

    unregisterReceiver() {
        if (this.messageSubscription && !this.messageSubscription.closed) {
            this.messageSubscription.unsubscribe();
        }
    }

    // isSupported() {
    //     return firebase.messaging.isSupported();
    // }
}
