import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'relativeDate'
})
export class RelativeDatePipe implements PipeTransform {


  constructor(
    private translate: TranslateService
  ) {
  }

  transform(value: any, args?: any[]): any {
    if (typeof  value == 'number') {
      value = new Date(value);
    }
    moment.locale(this.translate.currentLang);
    if (args?.indexOf('preventFuture') >= 0 && value > new Date()) {
      value = new Date();
    }
    const date = moment(value);
    if (date.isValid()) {
        return date.fromNow();
    }
    return this.translate.instant('Invalid date');
  }

}
