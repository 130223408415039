import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthenticationService,
    ) {}

    canActivate() {
      return this.authService.authState$.pipe(
        map(user => {
          if (user !== null && (user.emailVerified || this.isFacebookProvider(user))) {
            return true;
          }
          // this.toastrService.warning(this.translateService.instant('Session expired')); //FIXME
          this.router.navigate(['/login']); // FIXME: if not is already on this route
          return false;
        })
      );
    }

    isFacebookProvider(user) {
      return user.providerData.find(provider => provider.providerId === 'facebook.com');
    }
}
