import { Component, OnInit } from '@angular/core';
import { FirebaseMessagingService } from './@core/service/firebase-messaging.service';
import { AuthenticationService } from './shared/services/authentication.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { WebSocketListenersService } from './@core/service/web-socket-listeners.service';
import * as moment from 'moment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  template: `
    <ngx-loading-bar
      [includeSpinner]="false"
      color="#7bd5f7"
      [ref]="'http'"
      height="3px">
    </ngx-loading-bar>
    <router-outlet></router-outlet>
  `,
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loader = this.loadingBar.useRef();

  constructor(
    private messagingService: FirebaseMessagingService,
    private authService: AuthenticationService,
    private loadingBar: LoadingBarService,
    private wsWatchers: WebSocketListenersService,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    moment.locale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((l: LangChangeEvent) => moment.locale(l.lang));
    // if (this.messagingService.isSupported()) {
      this.authService.authState$.subscribe(user => {
        if (user) {
          this.messagingService.requestPermission();
          this.messagingService.receiveMessage();
        } else {
          this.messagingService.unregisterReceiver();
        }
      });
    // } else {
    //   console.warn('App notifications are not supported on this browser');
    // }
    this.wsWatchers.init();
  }
}
