<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{'Pair again' | translate}} {{'device' | translate}}</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
          (click)="modal.close(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{'Are you sure to pair again' | translate}} <span class="text-primary">{{deviceName}}</span>?</p>
  <p>
    <span class="text-danger">{{'It can take up to 30 minutes' | translate}}.</span>
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="modal.close(false)">{{'Cancel' | translate}}</button>
  <button type="button" ngbAutofocus class="btn btn-danger" (click)="modal.close(true)">{{'Ok' | translate}}</button>
</div>
