import { Component, OnInit, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
    @Input() items: PageHeaderItem[];
    constructor() {}

    ngOnInit() {}
}

export class PageHeaderItem {
  path?: string;
  name: string;
  icon: string;
}
