import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Device, DeviceAdapter } from '../model/device';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiDevicesService {
  public static createDevicesMap(devices: Device[]): {[id: number]: Device} {
    return devices
      .reduce((acc, device) => ({
        ...acc,
        [device.id]: true
      }), {})
  }

  constructor(
    private http: HttpClient,
    private deviceAdapter: DeviceAdapter,
  ) {
  }

  public addDevice(device: Device): Observable<Device> {
    return this.http.post<Device>(`${environment.apiUrl}/device/register`, device);
  }

  public getDevice(id: number): Observable<Device> {
    return this.http.get<Device>(`${environment.apiUrl}/device/${id}`).pipe(
      map(device => this.deviceAdapter.adapt(device)),
      take(1)
    );
  }

  public isDeviceRegistered(device: Device): Observable<boolean> {
    return this.http.get(`${environment.apiUrl}/device/${device.id}/is-registered`).pipe(
      map(response => true),
      catchError(err => of(false))
    );
  }

  public getDevices(): Observable<Device[]> {
    return this.http.get<Device[]>(`${environment.apiUrl}/device`)
      .pipe(
        map(response => response.map(device => this.deviceAdapter.adapt(device))),
        take(1)
      );
  }

  public updateDevice(device: Device): Observable<Device> {
    return this.http.put<Device>(`${environment.apiUrl}/device`, device).pipe(
      map(d => this.deviceAdapter.adapt(d)),
      take(1)
    );
  }

  deleteDevice(device: Device) {
    return this.http.delete(`${environment.apiUrl}/device/${device.id}`);
  }

  reRegister(device: Device): Observable<Device> {
    return this.http.post<Device>(`${environment.apiUrl}/device/re-register/${device.id}`, null);
  }
}
